import React, { useEffect, useState } from "react";
import "../assets/scss/partners/contents/lnb_menu.scss";
import { NavLink } from "react-router-dom";
import JobService from "../JobPostingManagement/services/JobService";
import Helper from "../JobPostingManagement/helper/helper";
import useCompanyInfo from "../partners/hooks/useCompanyInfo";
import { dL_ClickNewJobPostingInPartner, dL_clickFitMatchingInPartner } from "../DataLayer";


function JobLnbMenu() {
  const { jply_info } = useCompanyInfo();
  const [menuList, setMenuList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    total_count: 0,
    page: 1,
    per: 12,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [isExistMore, setExistMore] = useState(false);
  const [isFold, setFold] = useState(false);
  const [fitEnable, setfitEnable] = useState(false);

  useEffect(() => {
    getMenuList(pageInfo.page);
  }, [pageInfo.page]);

  const getMenuList = async (page) => {
    const result = await JobService.getJobPostingSimpleList(page, pageInfo.per);
    if (result?.code === 200) {
      const {data: {postings, paging, total_count, fit_enable}} = result;
      const isExistMoreBtn = paging.total_count > paging.page * paging.per
      setExistMore(isExistMoreBtn);
      setPageInfo(paging);
      setMenuList(menuList.concat(postings));
      setTotalCount(total_count);
      setfitEnable(fit_enable)
    }
  };

  const foldMenu = (e) => {
    e.preventDefault();
    setFold(fold => !fold);
  }

  const setMorePosting = () => {
    setPageInfo({ ...pageInfo, page: pageInfo.page + 1 })
  };

  const moveRegisterPosting = () => {
    const { company_id, company_name, job_posting_bizs, privilege_master_code } = jply_info?.user;

    if (!job_posting_bizs && privilege_master_code !== "premium_recruit") {
      window.open("https://tally.so/r/mV6Aam", "_blank", "noopener, noreferrer");
    } else {
      dL_ClickNewJobPostingInPartner({
        path: "/partners/job_management",
        section_title: "LNB",
        button_title: "+ 채용공고 등록",
        company_id: company_id,
        company_name: company_name,
      });
      window.open("/partners/job/postings/new", "_blank", "noopener, noreferrer");
    }
  }

  const amplitudeClick = (event, button, title, applicant_count) => {
    const isActive = event.target.className.includes('is_active');
    if (isActive) return;

    const { user_id, company_id, company_name } = jply_info?.user;

    const eventName = "click_button_in_partner_job_management";
    const data = {
      company_id: company_id,
      company_name: company_name,
      button: button,
      button_title: title,
    };

    jpamplitude.init(user_id);
    jpamplitude.sendEvent(eventName, data);
    if(applicant_count === 0){
      dL_clickFitMatchingInPartner({
        companyId: company_id,
        companyName: company_name,
        sectionTitle: "개별 공고",
        buttonTitle: "지원자 0"
      });
    }
  }



  return (
    <nav className="lnb_menu" role="navigation">
      <div className="lnb_menu__wrap">
        <div className="lnb_menu__top">
          <button
            type="button"
            className="jply_btn_sm ty_solid job_register_btn"
            onClick={moveRegisterPosting}
          >
            + 채용공고 등록
          </button>
        </div>
        <div className="lnb_menu__list">
          <ul className="menu_list">
            <li>
              <NavLink
                className="menu_list__item"
                id="lnb_job_management"
                activeClassName="is_active"
                to="/partners/job_management"
                onClick={(event) => amplitudeClick(event,"전체 공고 관리", "전체 공고 관리")}
              >
                전체 공고 관리
              </NavLink>
            </li>
            <li>
              <NavLink
                className="menu_list__item"
                id="lnb_applicant_management"
                activeClassName="is_active"
                to="/partners/applicant_management"
                onClick={(event) => amplitudeClick(event,"지원자 관리", "지원자 관리")}
              >
                지원자 관리
                {menuList.length > 0 && (
                  <button type="button" onClick={(e) => foldMenu(e)}>
                    <i className={`jp-chevron-${isFold ? "down" : "up"}`} />
                  </button>
                )}
              </NavLink>
              {!isFold && (
                <>
                  <ul className="menu_list__group">
                    <li>
                      <NavLink
                        className="menu_list__sub"
                        id="lnb_applicant_management"
                        activeClassName="is_active"
                        exact
                        to="/partners/applicant_management"
                        onClick={(event) => amplitudeClick(event,"지원자 관리", "전체 지원자")}
                      >
                        <div className="list_name">전체 지원자</div>
                        <p className="list-count">{totalCount > 9999 ? "9999+" : totalCount}</p>
                      </NavLink>
                    </li>

                    {menuList.map((posting, idx) => {
                      const badgeInfo = Helper.getBadgeInfo(posting.badge, posting.d_day);
                      const linkUrl = fitEnable && posting.applicant_count === 0 && posting.badge === "opened"
                          ? `/partners/applicant_management/${posting.id}?status=fit`
                          : `/partners/applicant_management/${posting.id}`;
                      return (
                        <li key={`${posting}${idx}`}>
                          <NavLink
                            className="menu_list__sub"
                            id={`lnb_applicant_management_${posting.id}`}
                            activeClassName="is_active"
                            isActive={(match, location) => {
                              if (match) {
                                return true;
                              }
                              /*fit으로 바로 랜딩되는 경우 메뉴 볼드처리*/
                              const url = linkUrl.split("?")
                              if(url[0] === location.pathname && `?${url[1]}` === location.search){
                                return true;
                              }
                              return false;
                            }}
                            to={linkUrl}
                            onClick={(event) => amplitudeClick(event,"지원자 관리", posting.title, posting.applicant_count)}
                            data_jid={posting.id}
                            data_jname={posting.title}
                          >
                            <div className="list_name">
                              <p className={`jp_badge ${badgeInfo.color}`}>{badgeInfo.name}</p>
                              <p className="sub_text">{posting.title}</p>
                            </div>
                            <p className="list_count">{posting.applicant_count > 99 ? "99+" : posting.applicant_count}</p>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                  {isExistMore && (
                    <button type="button" className="jply_btn_sm ty_text menu_list__btn" onClick={setMorePosting}>
                      더보기 <i className="jp-chevron-down"></i>
                    </button>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default JobLnbMenu;
