import axios from "axios";
import { localStorageGet } from "../../sagas/saga_helper";
import {STATUS_CODES, API_CALL_TIMEOUT} from "../../Helper/HTTPConstants"

const api = axios.create({
  timeout: API_CALL_TIMEOUT,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const accessErrorUrl = "/partners/access_error"
    if (error.response
      && error.response.status === STATUS_CODES.FORBIDDEN
      && !window.location.href.includes(accessErrorUrl)
      && (localStorage.getItem("jobplanet_login") !== "login_check_error")
    ) {
      window.location.href = accessErrorUrl;
    }
    
    if (error.response && error.response.status === STATUS_CODES.FOUND) {
      const redirectLocation = error.response.headers.location;
      if (!window.location.href.includes(redirectLocation)){
        window.location.href = redirectLocation;
      }
    }
    return Promise.reject(error);
  }
);

export default function fetchDog(option) {
  return api({
    baseURL: process.env.REACT_APP_BACKEND_HOST,
    method: "get",
    url: option.url,
    params: Object.assign({}, option.params, {access_token: localStorageGet('access_token')}),
    headers: {
      access_token: localStorageGet('access_token'),
      Accept: 'application/json'
    },
    withCredentials: true
  });
}
